#ku-edit-info {
  transition: 300ms;
  width: 98%;
  padding: 15px;
  margin-bottom: 15px;
  border: 2px solid #F9F9F9;
  border-radius: 4px;
  color: #3a3a3a;
  background-color: #F9F9F9;
  font-size: 14px;
  box-sizing: border-box;
}
.ku-edit-info-select-2nd {
  /* border-color: #337eca !important;
  background-color: #ebf0f5 !important;
  color: #6363ec !important; */
}
.ku-edit-info-active {
  border: 2px solid #6781B8 !important;
  background-color: #EEF2F9 !important;
}

.ku-edit {
  min-width: 800px;
  padding: 5px;
}
.ku-edit-display {
  display: inline-block;
  margin-bottom: 15px;
}
.ku-edit-phrase {
  display: inline-block;
  border: dashed 1px #bbb;
  border-radius: 5px;
  padding: 7px;
  margin-top: 7px;
  margin-right: 7px;
  vertical-align: top;
}
.ku-edit-filler {
  background-color: #e5effe;
  border-color: #308db8;
  border-radius: 3px;
  font-size: 0.6em;
  padding: 7px 3px;
}

.ku-edit-word {
  transition: 0.2s;
  display: inline-block;
  border: solid 1px #bbb;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 0.85em;
  color: #6c6c6c;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.29);
  cursor: pointer;
}
.ku-edit-word.slot {
  border: 1px solid #f50f79;
  color: #f50f79;
}
.ku-edit-filler .ku-edit-word {
  padding: 10px 0 0;
  border: none;
  height: 22px;
  box-shadow: none;
}

.ku-edit-word:active {
  box-shadow: inset 0 0 2px rgba(128, 128, 128, 0.1);
  transform: translateY(2px);
}
.ku-edit-word:last-of-type {
  margin-right: 0;
}
.ku-edit-word-selected {
  color: #deece0;
  background-color: #37a51c;
  border-color: #2e6335;
}
.ku-edit-word-selectable:hover {
  background-color: #5aa4f3;
  color: #f3f3f3;
}
.ku-edit-word-selectable {
  color: #1a0dab;
  background-color: #ebf0f5;
}
.ku-edit-word-selectable:after {
  content: "";
  display: block;
  height: 1px;
  background-color: #1a0dab;
  border-radius: 1px;
  margin: -1px auto 0;
}
.ku-edit-word-selectable:after:hover {
  display: none;
  height: 0;
}
.ku-edit-word > span {
  display: inline-block;
}
.ku-edit-filler > span:nth-of-type(1) {
  display: inline-block;
  color: #308db8;
}
.accent-merge-controll {
  margin-bottom: 5px;
}
#accent-merge-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-size: 0.9em;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;

  color: #fff;
  background-color: #ff7f4c;
  border-color: #ff7f4c;
  padding: 0.375rem 0.7rem;
  cursor: pointer;
}
#accent-merge-button:disabled {
  background-color: #b9b9b9;
  border-color: #b9b9b9;
  cursor: not-allowed;
}

.TextGroup {
  display: inline-block;
  text-align: left;
  padding-left: 15px;
  vertical-align: top;
}
.TextGroup .hirabun {
  display: block;
  font-size: 26px;
  height: 42px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  border-bottom: solid 2px #bbb;
  letter-spacing: 2px;
  color: #444;
  cursor: default;
}
.TextGroup .hirabun span {
  vertical-align: middle;
}
.TextGroup .duration {
  font-size: 26px;
  border-color: #697cdc;
}
.TextGroup .yomi {
  display: inline-block;
  padding: 5px;
  height: 24px;
  font-size: 13px;
  letter-spacing: 1.5px;
}
.TextGroup .morph-type-slot {
  display: inline-block;
  border: 2px solid #f50f79;
  color: #f50f79;
  height: auto;
  padding: 2px 6px 0;
  margin: 0 2px;
  border-radius: 3px;
  background-color: #f7ecf1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.TextGroup .yomi-word,
.TextGroup .yomi-word-filler {
  display: inline-block;
  margin-right: 3px;
}
.TextGroup .yomi-word:after {
  content: "";
  display: block;
  width: 85%;
  height: 1px;
  background-color: #999;
  border-radius: 1px;
  margin: 4px auto 0;
}
.TextGroup .yomi-word-filler:after {
  content: "";
  display: block;
  width: 85%;
  height: 2px;
  background-color: #b8b3f7;
  border-radius: 1px;
  margin: 4px auto 0;
}

.TextGroup .yomi span {
  display: inline-block;
  height: 20px;
  width: 16px;
  vertical-align: bottom;
  line-height: 32px;
}
span.accent {
  font-size: 14px;
  color: red;
  background-image: url("bg_accent.svg");
  background-repeat: no-repeat;
  background-position-x: 3px;
  /* background: #ffc8ca;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 10px; */
}
span.chars-accent {
  cursor: pointer;
}

.tooltip-duration .rc-tooltip-inner {
  background-color: #364073;
}

.yomi-editor {
  padding: 5px;
  height: auto;
  width: 290px;
}
.yomi-parts {
  display: inline-block;
  width: 80px;
}
.yomi-dic {
  display: inline-block;
  width: 60px;
  padding: 0 5px;
}
.yomi-filler {
  font-weight: bold;
  color: #b8b3f7;
}
.yomi-slot {
  font-weight: bold;
  color: #ff75b5;
}
.yomi-input {
  display: inline-block;
  width: 140px;
  padding: 2px;

  position: relative;
  box-sizing: border-box;
  height: auto;
  font-size: 0.9em;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.yomi-slot + .yomi-input {
  border-color: #ffaad1;
}

.duration-editor {
  width: 280px;
  padding: 10px;
  height: 80px;
}

.duration-slider {
  margin-top: 20px;
}
.duration-slider .rc-slider-mark-text-active {
  color: #b4e5fb;
}

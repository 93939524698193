.setting {
  margin-bottom: 60px;
}
.setting .react-tabs__tab {
  color: #999;
}
.setting .react-tabs__tab--selected {
  font-weight: 500;
  color: #2a5582;
}
.setting-tab {
  margin: 0;
  border-bottom: solid 1px #bbb;
  font-size: 0.9em;
}
.setting .react-tabs__tab-panel--selected {
  box-sizing: border-box;
  padding: 10px;
}
.setting-inner {
  width: 800px;
  text-align: left;
}
.yomi-form {
}
.ku-form {
  width: 100%;
}
.option-form {
}
.debug-panel {
  width: 100%;
}

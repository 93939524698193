#editor {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.editor-navi {
  min-width: 800px;
  height: 38px;
  margin-bottom: 5px;
}
.editor-navi-bredcrumb {
  width: 50%;
  display: inline-block;
  padding: 8px;
  font-size: 0.8em;
  color: #5e6c84;
  vertical-align: middle;
}
.editor-navi-bredcrumb span {
  padding: 0 3px;
}
.editor-navi-bredcrumb span a.test-input-link:link,
.editor-navi-bredcrumb span a.test-input-link:visited {
  text-decoration: none;
  color: blue;
}
.editor-navi-bredcrumb span a.test-input-link:hover {
  text-decoration: underline;
}

.editor-navi-info {
  margin-bottom: 2px;
  font-size: 0.7em;
  color: #5e6c84;
  text-align: right;
  margin-right: 20px;
}

.play {
  display: inline-block;
  height: 38px;
  text-align: right;
  float: right;
  margin-right: 20px;
  box-sizing: border-box;
}
.editor-content {
  overflow-y: auto;
  height: 100%;
  min-height: 800px;
  box-sizing: border-box;
}

.error {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  font-size: 0.9em;
}

.input-text {
  display: inline-block;
  height: auto;
  width: 90%;
  margin-top: 26px;
  margin-left: 10px;
  padding: 8px 4px 3px;
  font-size: 26px;
  letter-spacing: 1px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  box-sizing: border-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.label-header {
  display: inline-block;
  width: 200px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.175rem 0;
  font-size: 0.8rem;
  line-height: 1.5;
  vertical-align: top;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  color: #fff;
  background-color: #6c6c6c;
  border-color: #6c6c6c;
  margin-bottom: 10px;
}

.display-text {
  padding: 15px;
  background-color: #ffffe4;
  border-radius: 15px;
  margin-bottom: 10px;
}

.spinner {
  margin: 100px auto;
  width: 120px;
  height: 60px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 8px;
  display: inline-block;
  margin: 1px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.spinner .rect6 {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1.3);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1.3);
    -webkit-transform: scaleY(1.3);
  }
}

.mode-btn {
  display: inline-block;
  font-weight: 200;
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  border-radius: 0.15rem;
  color: #666;
  background-color: #fff;
  border-color: #b3b3b3;
  padding: 0.1rem 0.05rem;
}

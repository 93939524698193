.editor-sentence {
  padding: 5px;
  border-bottom: 1px solid #ced4da;
  transition: all 0.3s;
}
.editor-duration {
  background-color: #e5effe;
}
.sentence-label {
  display: inline-block;
  width: 240px;
  padding: 5px 0 0 10px;
  font-size: 1.2em;
  vertical-align: top;
}
.sentence-form {
  display: inline-block;
  width: 520px;
  font-size: 0.9em;
}

.yomi-edit {
  display: inline-block;
  padding: 3px 5px;
  height: auto;
}
.yomi-edit > .label {
  display: inline-block;
  height: auto;
  width: 140px;
  padding: 5px;
  color: #6c6c6c;
}

.yomi-edit > .dic {
  display: inline-block;
  width: 110px;
  padding: 0 5px;
  color: #6c6c6c;
}
.yomi-edit > .filler {
  font-weight: bold;
  color: #b8b3f7;
}

.yomi-edit > .slot {
  color: #f50f79;
}
.yomi-edit > .slot + .edit {
  border-color: #ffaad1;
}

.yomi-edit > .edit {
  display: inline-block;
  width: 240px;
  padding: 3px 5px;

  position: relative;
  box-sizing: border-box;
  height: auto;
  font-size: 0.9em;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.yomi-edit .add-kuten {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}
.yomi-edit .add-kuten button {
  display: inline-block;
  padding: 2px 5px;
  color: #3a3a3a;
  border: 1px solid #479cf7;
  border-radius: 5px;
  background-color: #d2e6fb;
  cursor: pointer;
  font-size: 0.8em;
  float: right;
  margin-right: -55px;
  transition: back-ground-color 0.15s ease-in-out;
}
.yomi-edit .add-kuten button:hover {
  background-color: #479cf7;
  color: #eee;
}
.yomi-edit .add-kuten button:hover:after {
  content: "";
  width: 570px;
  height: 1px;
  background-color: #479cf7;
  border-radius: 1px;
  margin: 4px auto 0;
  left: 520px;
  position: absolute;
  display: block;
  margin-top: 8px;
  content: "";
}

.duration-edit .remove-pause {
  float: right;
  margin-top: -7px;
  margin-right: -7px;
}
.duration-edit .remove-pause button {
  display: inline-block;
  padding: 2px 5px;
  border: 1px solid #479cf7;
  border-radius: 5px;
  background-color: #eee;
  cursor: pointer;
  font-size: 10px;
  color: #479cf7;
}
.duration-edit .remove-pause button:hover {
  background-color: #479cf7;
  color: #eee;
}

.duration-edit {
  box-sizing: border-box;
  padding: 3px 5px;
  width: 100%;
}
.duration-edit-title {
  display: inline-block;
  width: 100px;
  text-align: center;
}
.duration-edit-title > span {
  display: inline-block;
  box-sizing: border-box;
  width: 100px;
  font-size: 0.8em;
  color: #479cf7;
  border-bottom: 1px solid #479cf7;
  padding: 0 3px;
  margin-right: 8px;
  margin-bottom: 5px;
}

.duration-edit .duration-slider {
  display: inline-block;
  box-sizing: border-box;
  width: 350px;
  margin: 20px 0 0 40px;
  vertical-align: top;
}
.duration-edit .rc-slider-handle {
  border-color: #479cf7;
}
.duration-edit .rc-slider-track {
  background-color: #89bff9;
}
.duration-edit .rc-slider-dot-active {
  border-color: #479cf7;
}
.duration-edit .duration-slider .rc-slider-mark-text-active {
  color: #479cf7;
}
.duration-yomi-edit {
  margin-bottom: 5px;
}
.duration-yomi-edit input {
  display: none;
}
.duration-yomi-edit label {
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  width: 50px;
  margin: 0;
  padding: 8px 10px;
  background-color: #e7e8e8;
  border: 1px solid #ced4da;
  color: #869198;
  font-size: 0.8em;
  text-align: center;
  line-height: 1;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.duration-yomi-edit label:hover {
  background-color: #d2e6fb;
  border-color: #d2e6fb;
}
.duration-yomi-edit label:first-of-type {
  border-radius: 5px 0 0 5px;
}
.duration-yomi-edit label:last-of-type {
  border-radius: 0 5px 5px 0;
}

.duration-yomi-edit input[type="radio"]:checked + label {
  cursor: default;
  border-color: #007bff;
  background-color: #479cf7;
  color: #fff;
}

table.debug-morph-data,
table.debug-morph-data th,
table.debug-morph-data td {
  border-collapse: collapse;
  border: 1px solid #333;
  font-size: 12px;
  width: 50px;
  white-space: word-break;
}

table.debug-morph-data {
  margin-bottom: 10px;
  width: 1000px;
}

table.debug-morph-data th {
  text-align: center;
  background-color: #f4f5f7;
  font-weight: bold;
  padding: 3px 5px;
}
table.debug-morph-data td {
  padding: 3px 5px;
}

table.debug-morph-data th.accent {
  background-color: #deebff;
}
table.debug-morph-data tr td:nth-of-type(1),
table.debug-morph-data tr td:nth-of-type(10),
table.debug-morph-data tr td:nth-of-type(11),
table.debug-morph-data tr td:nth-of-type(14) {
  background-color: #fffae5;
  font-size: 12px;
  white-space: nowrap;
}

.rawdata {
  width: 90%;
  background-color: #364549;
  color: #e3e3e3;
  padding: 0.5em;
  box-sizing: border-box;
}
.rawdata code {
  font-size: 12px;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier,
    monospace;
  line-height: 1.8;
}
